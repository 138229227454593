.container-modal-header {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.container-modal-header .title {
  display: flex;
  justify-content: space-between;
}

.container-header-inputs {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.container-switch-detection {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.container-header-inputs .select-object-type {
  width: fit-content;
}

.container-header-inputs div button {
  width: 100%;
}

.btn-text-container .remove-btn {
  padding: 0 0.5rem;
}

@media (max-width: 767px) {
  .container-header-inputs {
    flex-direction: column;
    align-items: initial;
  }

  .container-header-inputs .select-object-type {
    width: 100% !important;
  }
}
