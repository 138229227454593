
div:has(> #modal-object-details) {
  z-index: 1010 !important;
}

.btn-text-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: .2rem 0;
}

.btn-text-container small {
  font-size: .7em;
}

.btn-text-container .remove-btn {
  padding: 0 .5rem;
}

.detections-container {
  display: flex;
  gap: 3rem;
  padding: 1rem;
  overflow-y: auto;
  cursor: grab;
  touch-action: none;
  direction: rtl;
  transform: rotate(180deg);
  overflow-x: scroll;
  margin-top: .5rem;
}

.item {
  cursor: pointer;
  transform: rotate(-180deg);
}

@media (max-width: 767px) {
  .offcanvas {
    width: 100% !important;
  }
}
