.modal-new-annotation h5 {
  text-align: center;
  word-break: break-word;
  font-size: 18px;
}

.body-modal-new-annotation {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}
