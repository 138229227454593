.container-labels-color-circle {
  display: flex;
  gap: 1rem;
  padding: 1rem 1rem 0;
}

.label-color-circle {
  display: flex;
  gap: .5rem;
}

.loading {
  position: absolute;
  justify-content: center;
  display: flex;
  width: 100%;
  margin-top: 2rem;
}
