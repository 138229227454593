@import url("https://fonts.googleapis.com/css?family=Maven Pro");

/* body {
    background-image: linear-gradient(to bottom right,rgb(223, 237, 240), rgb(213, 217, 255));
    font-family: 'Maven Pro', serif;
    padding-top: 55px;
    height: 100vh;
} */

section {
  background: linear-gradient(
    to top left,
    #68f1fc 2%,
    #0082ff 44%,
    #202e79 100%
  );
  font-family: "Maven Pro", serif;
  height: 100%;
  overflow: auto;
}

form {
  display: flex;
  flex-direction: column;
  background: linear-gradient(to top left, #ffffff 2%, #ffffff 43%);
  padding: 3.5rem 2.5rem;
  border-radius: 50px;
  gap: 3rem;
}

.street-census-logo-container {
    display: none;
}

.logo_container {
  align-self: center;
  max-width: 300px;
}

.logo_container img {
  width: 100%;
  max-height: 600px;
}

.inputs_container input {
  background-color: #92afd3;
  font-size: 1.2em;
  border-radius: 25px !important;
}

.inputs_container,
.btns_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.btns_container {
  align-self: center;
  width: 80%;
}

#btnEntrar {
  border: none;
  padding: 0.5rem;
}

.mascot_container {
  position: relative;
}

.mascot {
  position: absolute;
  height: 280px;
  top: -155px;
  left: -157px;
}



@media (max-width: 767px) {
  .street-census-logo-container {
    display: flex;
    justify-content: center;
  }

  .street-census-logo-container img {
    max-width: 40vw;
    margin-bottom: 1rem;
  }

  .car-container {
    display: none;
  }

  .login-form { 
    width: 100% !important;
  }

  .mascot {
    display: none;
  }
}