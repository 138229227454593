@import url('https://unpkg.com/leaflet@1.5.1/dist/leaflet.css');

h1, h2, p {
  text-align: center;
}
.leaflet-container {
  position: absolute;
  height: 400px;
  width: 100%;
  margin: 0 auto;
}

.icon {
  color: rgba(0, 0, 0, .5) !important;

  width: 33px;
}