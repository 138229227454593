.leaflet-right {
  right: 10%;
}

.leaflet-top {
  top: 7%;
}

@media (max-width: 767px) {
  .leaflet-right {
    right: 25%;
  }

  .leaflet-top {
    top: 12%;
  }
}
