.checkbox-container {
  display: flex;
  gap: .5rem;
  font-size: 1rem;
  padding: 0 1rem 1rem;
  flex-direction: column;
}

.all-detections-checkbox {
  display: flex;
  gap: .5rem;
}

.filter-options-container-carousel {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 0;
  max-width: 100%;
}

.filter-option-carousel {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  cursor: pointer;
}

.carousel-inner {
  touch-action: none; 
}

.carousel-indicators {
  bottom: 30px;
}

.carousel-control-prev, .carousel-control-next, .carousel-indicators {
  z-index: 15;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
  width: 3rem;
  height: 3rem;
}