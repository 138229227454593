.num-of-poles {
  color: #fff;
  font-weight: bold;
  margin-left: auto;
  opacity: 1;
}

.current-annotation {
  color: #fff;
  margin-left: auto;
  opacity: 1;
}

.custom-eraser-button {
  height: 20px;
  width: 20px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.custom-eraser-button:hover svg {
  transform: scale(1.2);
}