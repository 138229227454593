.components-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.filter-options-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  max-width: 490px;
  margin-bottom: 1rem;
}

.filter-option {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  cursor: pointer;
}

.components-card {
  display: flex;
  gap: 1rem;
}

.remove-btn {
  font-size: 0.7em;
  padding: 0 0.5rem;
}

.div-canvas canvas {
  height: 100px !important;
  width: 100px !important;
}

.components-info-column {
  width: 280px;
}

.components-info-column span, strong {
  font-size: .9em;
}

@media (max-width: 767px) {
  .modal-pole-components-card {
    left: 0 !important;
    top: 0 !important;
    max-width: 100% !important;
    max-height: 100% !important;
    width: 100%;
    height: 100%;
  }
}
