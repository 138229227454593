.header-detection-modal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.header-detection-modal .container-title {
  display: flex;
  align-items: center;
}

.header-detection-modal .container-title h5 {
  margin: 0;
  margin-right: 1rem; /* Add some space between the h5 and the FormGroup */
  white-space: nowrap; /* Prevent line breaking */
}

.modal-content {
  padding: 0;
}


@media (max-width: 767px) {
  .modal-dialog {
    margin: 0;
  }
  
  .header-detection-modal .container-title {
    flex-direction: column;
  }
}