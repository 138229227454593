.modal-confirmation .modal-content {
  padding: .5rem .5rem 1rem !important;
}

.close-btn-container {
  display: flex;
  justify-content: flex-end;
  font-size: 1.8rem;
}

.close-btn-container svg {
  cursor: pointer;
}

.text-container {
  text-align: center;
  margin: .5rem 0;
}

.btn-container {
  display: flex;
  gap: 1rem;
  justify-content: center;
}